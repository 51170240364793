

// Fonts
// @import url("https://fonts.googleapis.com/css?family=Nunito");

// Variables
@import "variables";

// Bootstrap
// @import "~bootstrap/scss/bootstrap";
@import '~pikaday/css/pikaday.css';

@import '../fonts/grtsk/selected.css';
@import '../fonts/grtsk-tera/selected.css';

.se-pre-con {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background: url('/images/loader.gif') center no-repeat rgba(255, 255, 255, 0.5);
}

@keyframes blackWhite {
    0% {
        background-color: green;
    }
    100% {
        background-color: white;
    }
}

.succes-fader {
    background-color: transparent;

    animation-name: blackWhite;
    animation-iteration-count: 1;
    animation-duration: 2s;

    -webkit-animation-name: blackWhite;
    -webkit-animation-iteration-count: 1;
    -webkit-animation-duration: 1s;
}


.question a,
.sorry a,
.html-raw a 
 {
    color: #EB0000 !important;
    text-decoration: underline;
}

p {
    padding-bottom: 1em;
}

.ams_header {
    font-family: $font-family-headers-tera;
    font-weight: bold;
}

.ams_h2 {
    font-family: $font-family-headers;
    font-weight: normal;
    font-style: normal;
}

.outlined {
    -webkit-text-stroke: 1px #EB0000;
    color: rgba(255,255,255,0.2);
}
.outlined-gray {

    -webkit-text-stroke: 1px rgba(156, 163, 175, var(--tw-text-opacity));
    color: rgba(255,255,255,0.2);
}

.outlined:hover {
    -webkit-text-stroke: 0px black;
    color: black;
}


.skiplinks a:focus, .skiplinks a:active {
    left: 50%;
    top: 0;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    z-index: 999;
    position:relative;
}

.skiplinks a, .skiplinks a:visited {
    display: block;
    left: -5000px;
     position: absolute;
    top: -5000px;
}


h1 {
    word-break: break-word;
}

.ronde_21 { // west
background-color: #d0cccc;
}

.ronde_23 { // oost
background-color: #fff6f6;
}

.ronde_24 { // w/nw west
background-color: #ffffff;
}