@tailwind base;
@tailwind components;
@tailwind utilities;


@layer base {


  h1 {
    @apply text-4xl;
    @apply font-bold;
  }

  h2 {
    @apply text-3xl;
  }

  h3 {
    @apply text-2xl;
  }

  h4 {
    @apply text-xl;
  }

  h5 {
    @apply text-lg;
  }

  h6 {
    @apply text-base;
  }


}

html {
  scroll-behavior: smooth;
}



/* .a750logo {
    max-width:200px;
  } */

/* .tooltip {
    @apply absolute invisible;
  }
  
  .has-tooltip:hover .tooltip {
    @apply visible z-50
  } */



.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
  position: absolute;
  bottom: 125%;
  left: 50%;
  visibility: hidden;
  z-index: 1;

  /* Cosmetics */
  color: #fff;
  background-color: #555;
  width: 120px;
  text-align: center;
  padding: 5px 0;
  margin-left: -60px;
  border-radius: 6px;
  opacity: 0;
  transition: opacity 1s;
}


/**
* Show the tooltip when you mouse over the
* tooltip container
*/
.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.main li {
  @apply list-disc;
  margin-left: 2em;
}

/*== start of code for tooltips ==*/
.tool {
  cursor: help;
  position: relative;
}


/*== common styles for both parts of tool tip ==*/
.tool .before,
.tool .after {
  left: 50%;
  opacity: 0;
  position: absolute;
  z-index: -100;
  content: none;
}

.tool:hover .before,
.tool:focus .before,
.tool:hover .after,
.tool:focus .after {
  opacity: 1;
  transform: scale(1) translateY(0);
  z-index: 100;

}

/*== pointer tip ==*/
/* .tool .before {
    border-style: solid;
    border-width: 1em 0.75em 0 0.75em;
    border-color: #3E474F transparent transparent transparent;
    bottom: 100%;
    content: "";
    margin-left: -0.5em;
    transition: all .65s cubic-bezier(.84,-0.18,.31,1.26), opacity .65s .5s;
    transform:  scale(.6) translateY(-90%);
}  */

/* .tool:hover .before,
.tool:focus .before {
    transition: all .65s cubic-bezier(.84,-0.18,.31,1.26) .2s;
} */


/*== speech bubble ==*/
/* .tool .after {
    background: #3E474F;
    border-radius: .25em;
    bottom: 180%;
    color: #EDEFF0;
    content: attr(data-tip);
    margin-left: -8.75em;
    padding: 1em;
    transition: all .65s cubic-bezier(.84,-0.18,.31,1.26) .2s;
    transform:  scale(.6) translateY(50%);  
    width: 25em;
} */

.tool .after {
  background: #3e474f;
  border-radius: 0.25em;
  bottom: 180%;
  color: #edeff0;
  content: attr(data-tip);
  margin-left: -23vw;
  padding: 1em;
  transition: all .65s cubic-bezier(.84, -0.18, .31, 1.26) .2s;
  transform: scale(.6) translateY(50%);
  width: 25vw;
}


.tool-left .after {
  margin-left: 10px;
  width: 50vw;
  max-width: 500px;
  bottom: 10px;
}

/* .tool:hover .after,
.tool:focus .after  {
    transition: all .65s cubic-bezier(.84,-0.18,.31,1.26);
} */

/* @media (max-width: 760px) {
  .tool .after { 
        font-size: .75em;
        margin-left: -5em;
        width: 10em; 
  }
} */

@media print {
  .print-hidden {
    display: none;

  }
}


fieldset p {
  padding-bottom: 2px;
}