// Body
$body-bg: #f8fafc;

// Typography
$font-family-sans-serif: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-family-headers: 'Grtsk Exa', Arial, Helvetica, sans-serif;
$font-family-headers-tera: 'Grtsk Tera', Arial, Helvetica, sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

// Colors

$blue: #3490dc;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #FF0000;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;

